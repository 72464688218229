<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <h1 class="text-3xl font-bold mb-6 text-purple-800">Docenten helpen je graag</h1>

    <!-- Search and Filters -->
    <div class="mb-8 space-y-4 bg-white rounded-lg shadow-md p-6">
      <div class="relative">
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Zoek op naam, vaardigheden of beschrijving..."
          class="pl-10 w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
        />
        <svg class="w-5 h-5 text-gray-400 absolute left-3 top-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>

      <div class="flex gap-2 flex-wrap">
        <button
          v-for="category in availableCategories"
          :key="category"
          @click="toggleCategory(category)"
          :class="[
            'px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200',
            selectedCategories.includes(category)
              ? 'bg-purple-600 text-white'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          ]"
        >
          {{ category }}
        </button>
      </div>
    </div>

    <!-- Add Teacher Button (only visible to teachers without a profile) -->
    <div v-if="isTeacher && !hasProfile" class="mb-6">
      <button
        @click="showAddTeacherModal = true"
        class="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700"
      >
        Docent Profiel Aanmaken
      </button>
    </div>

    <!-- Teachers Grid -->
    <div v-if="loading" class="text-center py-8">
      <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
      <p class="mt-2 text-gray-600">Docenten laden...</p>
    </div>

    <div v-else-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
      {{ error }}
    </div>

    <div v-else-if="filteredTeachers.length === 0" class="text-center py-8">
      <p class="text-gray-600">Geen docenten gevonden.</p>
    </div>

    <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div v-for="teacher in filteredTeachers" :key="teacher.id" class="bg-white rounded-lg shadow-md overflow-hidden">
        <div class="p-6">
          <div class="flex justify-between items-start">
            <div>
              <h2 class="text-xl font-semibold text-purple-800">{{ teacher.name }}</h2>
              <p class="text-gray-600">{{ teacher.department }}</p>
            </div>
            <img
              v-if="teacher.photoUrl"
              :src="teacher.photoUrl"
              :alt="teacher.name"
              class="w-16 h-16 rounded-full object-cover"
            />
          </div>

          <div class="mt-4">
            <h3 class="font-medium mb-2">Vaardigheden:</h3>
            <div class="flex flex-wrap gap-2">
              <span 
                v-for="skill in teacher.skills" 
                :key="skill"
                class="bg-purple-100 text-purple-800 text-sm px-2 py-1 rounded"
              >
                {{ skill }}
              </span>
            </div>
          </div>

          <div class="mt-4">
            <h3 class="font-medium mb-2">Over:</h3>
            <p class="text-gray-700">{{ teacher.about }}</p>
          </div>

          <div class="mt-4">
            <h3 class="font-medium mb-2">Beschikbaarheid:</h3>
            <p class="text-gray-700">{{ teacher.availability }}</p>
          </div>

          <div class="mt-6">
            <p class="text-gray-700">
              <span class="font-medium">Contact: </span>
              <a 
                :href="`mailto:${teacher.email}`"
                class="text-purple-600 hover:text-purple-800"
              >
                {{ teacher.email }}
              </a>
            </p>
          </div>

          <!-- Edit/Delete buttons only visible to the teacher who owns the profile -->
          <template v-if="isTeacher && canEditTeacher(teacher)">
            <div class="mt-4 flex gap-4">
              <button
                @click="editTeacher(teacher)"
                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Bewerken
              </button>
              <button
                @click="deleteTeacher(teacher)"
                class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Verwijderen
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Add/Edit Teacher Modal -->
    <div v-if="showAddTeacherModal || showEditTeacherModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div class="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div class="p-6">
          <div class="flex justify-between items-center mb-6">
            <h3 class="text-xl font-semibold">
              {{ showEditTeacherModal ? 'Docent Profiel Bewerken' : 'Docent Profiel Aanmaken' }}
            </h3>
            <button @click="closeModal" class="text-gray-400 hover:text-gray-500">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <form @submit.prevent="handleSubmit" class="space-y-4">
            <div>
              <label class="block text-sm font-medium text-gray-700">Naam</label>
              <input
                type="text"
                v-model="formData.name"
                required
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
              />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                v-model="formData.email"
                :readonly="true"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-50"
              />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">Afdeling</label>
              <input
                type="text"
                v-model="formData.department"
                required
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
              />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">Vaardigheden</label>
              <input
                type="text"
                v-model="skillsInput"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                placeholder="Voer vaardigheden in, gescheiden door komma's"
              />
              <p class="mt-1 text-sm text-gray-500">
                Bijvoorbeeld: JavaScript, Vue.js, PHP, MySQL
              </p>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">Over</label>
              <textarea
                v-model="formData.about"
                rows="3"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
              ></textarea>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">Beschikbaarheid</label>
              <input
                type="text"
                v-model="formData.availability"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
              />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">Foto URL</label>
              <input
                type="url"
                v-model="formData.photoUrl"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
              />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-2">Categorieën</label>
              <div class="space-y-2">
                <!-- Predefined categories -->
                <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                  <div v-for="category in availableCategories" :key="category">
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        v-model="formData.categories"
                        :value="category"
                        class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                      />
                      <span class="ml-2">{{ category }}</span>
                    </label>
                  </div>
                </div>

                <!-- Custom category input -->
                <div class="mt-4">
                  <label class="inline-flex items-center">
                    <input 
                      type="checkbox" 
                      v-model="showCustomCategory"
                      class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                    >
                    <span class="ml-2">Eigen categorie toevoegen</span>
                  </label>
                  
                  <div v-if="showCustomCategory" class="mt-2">
                    <div class="flex gap-2">
                      <input
                        type="text"
                        v-model="customCategory"
                        placeholder="Voer categorie naam in"
                        class="flex-1 rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                      />
                      <button
                        type="button"
                        @click="addCustomCategory"
                        class="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
                      >
                        Toevoegen
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Display custom categories -->
                <div v-if="customCategories.length > 0" class="mt-4">
                  <div v-for="category in customCategories" :key="category" class="flex items-center justify-between py-1">
                    <label class="inline-flex items-center">
                      <input 
                        type="checkbox" 
                        v-model="formData.categories" 
                        :value="category"
                        class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                      >
                      <span class="ml-2">{{ category }}</span>
                    </label>
                    <button
                      type="button"
                      @click="removeCustomCategory(category)"
                      class="text-red-600 hover:text-red-800"
                    >
                      <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-end gap-4">
              <button
                type="button"
                @click="closeModal"
                class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Annuleren
              </button>
              <button
                type="submit"
                class="px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700"
              >
                {{ showEditTeacherModal ? 'Opslaan' : 'Toevoegen' }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where } from 'firebase/firestore'
import { db } from '../firebase/config'
import { useAuthStore } from '../stores/auth'

const authStore = useAuthStore()
const isTeacher = computed(() => authStore.isTeacher)
const currentUserEmail = computed(() => authStore.user?.email)

const teachers = ref([])
const loading = ref(true)
const error = ref(null)
const searchTerm = ref('')
const selectedCategories = ref([])
const showAddTeacherModal = ref(false)
const showEditTeacherModal = ref(false)
const skillsInput = ref('')
const showCustomCategory = ref(false)
const customCategory = ref('')
const customCategories = ref([])

const hasProfile = computed(() => {
  return teachers.value.some(teacher => teacher.email === currentUserEmail.value)
})

const formData = ref({
  name: '',
  email: '',
  department: '',
  about: '',
  availability: '',
  photoUrl: '',
  skills: [],
  categories: []
})

watch(skillsInput, (newValue) => {
  if (newValue) {
    formData.value.skills = newValue.split(',')
      .map(skill => skill.trim())
      .filter(skill => skill.length > 0)
  } else {
    formData.value.skills = []
  }
})

watch(() => formData.value.skills, (newSkills) => {
  if (Array.isArray(newSkills) && !skillsInput.value) {
    skillsInput.value = newSkills.join(', ')
  }
}, { immediate: true })

const availableCategories = [
  'Web Development',
  'Mobile Development',
  'Data Science',
  'Machine Learning',
  'DevOps',
  'UI/UX Design',
  'Elektro',
  'Metaalbewerking',
  'Autotechniek',
  'Nederlands',
  'Engels',
  'Wiskunde',
  'Natuurkunde'
]

const addCustomCategory = () => {
  if (customCategory.value.trim()) {
    const newCategory = customCategory.value.trim()
    if (!customCategories.value.includes(newCategory)) {
      customCategories.value.push(newCategory)
      if (!formData.value.categories) {
        formData.value.categories = []
      }
      formData.value.categories.push(newCategory)
      customCategory.value = ''
    }
  }
}

const removeCustomCategory = (category) => {
  customCategories.value = customCategories.value.filter(c => c !== category)
  formData.value.categories = formData.value.categories.filter(c => c !== category)
}

onMounted(async () => {
  await fetchTeachers()
})

const fetchTeachers = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'teachers'))
    teachers.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }))
  } catch (err) {
    console.error('Error fetching teachers:', err)
    error.value = 'Er is een fout opgetreden bij het laden van de docenten. Probeer het later opnieuw.'
  } finally {
    loading.value = false
  }
}

const filteredTeachers = computed(() => {
  return teachers.value.filter(teacher => {
    const matchesSearch = 
      teacher.name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      teacher.skills.some(skill => skill.toLowerCase().includes(searchTerm.value.toLowerCase())) ||
      (teacher.about && teacher.about.toLowerCase().includes(searchTerm.value.toLowerCase()))

    const matchesCategories = 
      selectedCategories.value.length === 0 ||
      (teacher.categories && teacher.categories.some(category => selectedCategories.value.includes(category)))

    return matchesSearch && matchesCategories
  })
})

const toggleCategory = (category) => {
  const index = selectedCategories.value.indexOf(category)
  if (index === -1) {
    selectedCategories.value.push(category)
  } else {
    selectedCategories.value.splice(index, 1)
  }
}

const canEditTeacher = (teacher) => {
  return teacher.email === currentUserEmail.value
}

const editTeacher = (teacher) => {
  if (!canEditTeacher(teacher)) return

  formData.value = { ...teacher }
  skillsInput.value = teacher.skills.join(', ')
  // Set custom categories
  customCategories.value = teacher.categories.filter(
    category => !availableCategories.includes(category)
  )
  showEditTeacherModal.value = true
}

const deleteTeacher = async (teacher) => {
  if (!canEditTeacher(teacher)) return
  
  if (!confirm('Weet je zeker dat je je profiel wilt verwijderen?')) return

  try {
    await deleteDoc(doc(db, 'teachers', teacher.id))
    await fetchTeachers()
  } catch (err) {
    console.error('Error deleting teacher:', err)
    error.value = 'Er is een fout opgetreden bij het verwijderen van het profiel.'
  }
}

const handleSubmit = async () => {
  try {
    formData.value.skills = skillsInput.value.split(',')
      .map(skill => skill.trim())
      .filter(skill => skill.length > 0)

    // Set the email to the current user's email
    formData.value.email = currentUserEmail.value

    if (showEditTeacherModal.value) {
      // Only allow editing if the teacher owns the profile
      if (!canEditTeacher(formData.value)) {
        error.value = 'Je kunt alleen je eigen profiel bewerken.'
        return
      }

      const teacherId = formData.value.id
      const { id, ...updateData } = formData.value
      await updateDoc(doc(db, 'teachers', teacherId), updateData)
    } else {
      // Check if the teacher already has a profile
      if (hasProfile.value) {
        error.value = 'Je hebt al een profiel aangemaakt.'
        return
      }

      await addDoc(collection(db, 'teachers'), formData.value)
    }
    await fetchTeachers()
    closeModal()
  } catch (err) {
    console.error('Error saving teacher:', err)
    error.value = 'Er is een fout opgetreden bij het opslaan van het profiel.'
  }
}

const closeModal = () => {
  showAddTeacherModal.value = false
  showEditTeacherModal.value = false
  formData.value = {
    name: '',
    email: currentUserEmail.value,
    department: '',
    about: '',
    availability: '',
    photoUrl: '',
    skills: [],
    categories: []
  }
  skillsInput.value = ''
  customCategories.value = []
  showCustomCategory.value = false
  customCategory.value = ''
  error.value = null
}
</script>