<template>
  <div class="max-w-4xl mx-auto px-4 py-8">
    <Breadcrumb :crumbs="[
      { name: 'Home', path: '/' },
      { name: 'Dashboard', path: '/dashboard' },
      { name: 'Post Job', path: '/post-job' }
    ]" />

    <h1 class="text-3xl font-bold mb-8">Post a New Job</h1>

    <form @submit.prevent="handleSubmit" class="space-y-6">
      <!-- Basic Information -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label for="title" class="block text-sm font-medium text-gray-700">Job Title</label>
          <input
            type="text"
            id="title"
            v-model="job.title"
            required
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          />
        </div>

        <div>
          <label for="company" class="block text-sm font-medium text-gray-700">Company Name</label>
          <input
            type="text"
            id="company"
            v-model="job.company"
            required
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          />
        </div>

        <div>
          <label for="location" class="block text-sm font-medium text-gray-700">Location</label>
          <input
            type="text"
            id="location"
            v-model="job.location"
            required
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          />
        </div>

        <div>
          <label for="type" class="block text-sm font-medium text-gray-700">Job Type</label>
          <select
            id="type"
            v-model="job.type"
            required
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          >
            <option value="Full-time">Full-time</option>
            <option value="Part-time">Part-time</option>
            <option value="Internship">Internship</option>
            <option value="Project">Project</option>
          </select>
        </div>

        <div>
          <label for="experience" class="block text-sm font-medium text-gray-700">Experience Level</label>
          <select
            id="experience"
            v-model="job.experience"
            required
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          >
            <option value="Entry Level">Entry Level</option>
            <option value="Mid Level">Mid Level</option>
            <option value="Senior Level">Senior Level</option>
          </select>
        </div>

        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">Contact Email</label>
          <input
            type="email"
            id="email"
            v-model="job.email"
            required
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          />
        </div>
      </div>

      <!-- Dates -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label for="startDate" class="block text-sm font-medium text-gray-700">Start Date</label>
          <input
            type="date"
            id="startDate"
            v-model="job.startDate"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          />
        </div>

        <div>
          <label for="endDate" class="block text-sm font-medium text-gray-700">End Date</label>
          <input
            type="date"
            id="endDate"
            v-model="job.endDate"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          />
        </div>
      </div>

      <!-- Categories -->
      <div>
        <label class="block text-sm font-medium text-gray-700 mb-2">Categories</label>
        <div class="space-y-2">
          <!-- Predefined categories -->
          <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div v-for="category in availableCategories" :key="category">
              <label class="inline-flex items-center">
                <input 
                  type="checkbox" 
                  v-model="job.categories" 
                  :value="category"
                  class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                >
                <span class="ml-2">{{ category }}</span>
              </label>
            </div>
          </div>

          <!-- Custom category input -->
          <div class="mt-4">
            <label class="inline-flex items-center">
              <input 
                type="checkbox" 
                v-model="showCustomCategory"
                class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
              >
              <span class="ml-2">Add custom category</span>
            </label>
            
            <div v-if="showCustomCategory" class="mt-2">
              <div class="flex gap-2">
                <input
                  type="text"
                  v-model="customCategory"
                  placeholder="Enter category name"
                  class="flex-1 rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                />
                <button
                  type="button"
                  @click="addCustomCategory"
                  class="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <!-- Display custom categories -->
          <div v-if="customCategories.length > 0" class="mt-4">
            <div v-for="category in customCategories" :key="category" class="flex items-center justify-between py-1">
              <label class="inline-flex items-center">
                <input 
                  type="checkbox" 
                  v-model="job.categories" 
                  :value="category"
                  class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                >
                <span class="ml-2">{{ category }}</span>
              </label>
              <button
                type="button"
                @click="removeCustomCategory(category)"
                class="text-red-600 hover:text-red-800"
              >
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Skills -->
      <div>
        <label class="block text-sm font-medium text-gray-700 mb-2">Skills</label>
        <input
          type="text"
          v-model="skillsInput"
          placeholder="Enter skills separated by commas"
          class="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
        />
        <p class="mt-1 text-sm text-gray-500">
          Example: JavaScript, Vue.js, PHP, MySQL
        </p>
      </div>

      <!-- Job Description -->
      <div>
        <label class="block text-sm font-medium text-gray-700 mb-2">Job Description</label>
        <JobEditor v-model="job.about" />
      </div>

      <!-- Company Logo -->
      <div>
        <label class="block text-sm font-medium text-gray-700">Company Logo</label>
        <input
          type="file"
          accept="image/*"
          @change="handleLogoUpload"
          class="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-purple-50 file:text-purple-700 hover:file:bg-purple-100"
        />
        <img
          v-if="job.imageUrl"
          :src="job.imageUrl"
          alt="Company logo"
          class="mt-2 h-32 w-32 object-contain"
        />
      </div>

      <!-- YouTube Video -->
      <div>
        <label for="youtube_link" class="block text-sm font-medium text-gray-700">YouTube Video URL</label>
        <input
          type="text"
          id="youtube_link"
          v-model="job.youtube_link"
          placeholder="Paste YouTube URL here"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
        />
      </div>

      <!-- Submit Buttons -->
      <div class="flex justify-end space-x-4">
        <button
          type="button"
          @click="$router.push('/dashboard')"
          class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          :disabled="isSubmitting"
          class="inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        >
          {{ isSubmitting ? 'Posting...' : 'Post Job' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { db, storage } from '../firebase/config'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useAuthStore } from '../stores/auth'
import Breadcrumb from '../components/Breadcrumb.vue'
import JobEditor from '../components/JobEditor.vue'

const router = useRouter()
const authStore = useAuthStore()

const job = ref({
  title: '',
  company: '',
  location: '',
  type: '',
  experience: '',
  email: '',
  startDate: '',
  endDate: '',
  categories: [],
  skills: [],
  about: '',
  imageUrl: '',
  youtube_link: '',
  isActive: true
})

const isSubmitting = ref(false)
const showCustomCategory = ref(false)
const customCategory = ref('')
const customCategories = ref([])
const skillsInput = ref('')

const availableCategories = [
  'Mbo',
  'Werken & Leren',
  'Mavo / havo / vwo',
  'Web Development',
  'Mobile Development',
  'Data Science',
  'Machine Learning',
  'DevOps',
  'UI/UX Design',
  'Elektro',
  'Metaalbewerking',
  'Autotechniek'
]

watch(skillsInput, (newValue) => {
  if (newValue) {
    job.value.skills = newValue.split(',')
      .map(skill => skill.trim())
      .filter(skill => skill.length > 0)
  } else {
    job.value.skills = []
  }
})

const addCustomCategory = () => {
  if (customCategory.value.trim()) {
    const newCategory = customCategory.value.trim()
    if (!customCategories.value.includes(newCategory)) {
      customCategories.value.push(newCategory)
      if (!job.value.categories) {
        job.value.categories = []
      }
      job.value.categories.push(newCategory)
      customCategory.value = ''
    }
  }
}

const removeCustomCategory = (category) => {
  customCategories.value = customCategories.value.filter(c => c !== category)
  job.value.categories = job.value.categories.filter(c => c !== category)
}

const handleLogoUpload = async (event) => {
  const file = event.target.files[0]
  if (!file) return

  try {
    const fileRef = storageRef(storage, `companyLogos/${authStore.user.uid}/${Date.now()}_${file.name}`)
    await uploadBytes(fileRef, file)
    job.value.imageUrl = await getDownloadURL(fileRef)
  } catch (error) {
    console.error('Error uploading logo:', error)
    alert('Failed to upload logo. Please try again.')
  }
}

const handleSubmit = async () => {
  if (!authStore.user) {
    alert('You must be logged in to post jobs')
    return
  }

  try {
    isSubmitting.value = true
    
    const jobData = {
      ...job.value,
      companyId: authStore.user.uid,
      postedOn: serverTimestamp(),
      approved: false
    }

    await addDoc(collection(db, 'jobListings'), jobData)
    router.push('/company-job-listings')
  } catch (error) {
    console.error('Error posting job:', error)
    alert('Failed to post job. Please try again.')
  } finally {
    isSubmitting.value = false
  }
}
</script>